/*

? First, let's import the required components.

*/
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Chip,
	Collapse,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Tab,
	Tabs,
	useMediaQuery,
	useTheme,
	Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";
import React from "react";
import {
	ofb_property_category_options_active,
	property_category_options_active,
} from "../../../../lib/data/search-filters/_d_property_category";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import BedroomComponent from "./BedroomComponent";
import BudgetComponent from "./BudgetComponent";
import FacingDirectionComponent from "./FacingDirectionComponent";
import ListedByComponent from "./ListedByComponent";
import LocationAutoComplete from "./LocationAutoComplete";
import PropertyCategory from "./PropertyCategory";
import PropertyStatusComponent from "./PropertyStatusComponent";
import PropertyType from "./PropertyType";
import ServiceCategoryComponent from "./ServiceCategoryComponent";
import ServiceSubTypeComponent from "./ServiceSubTypeComponent";
/*

& Next, let's create the component.

*/

/*

* Overiding the default styles of the Accordion Component

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	padding: "0rem 1rem 0rem 1rem",
	height: "9.7438rem",
	backgroundColor: theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
	width: "fit-content",
	alignSelf: "start",
	borderRadius: "8px",
	"&.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-indicator": {
		display: "none",
	},
	minHeight: "0rem",
	height: "auto",
})) as typeof Tabs;

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: theme.palette.mode === "dark" ? "rgba(255, 255, 255,0.23)" : "1px solid #D9D9D9",
	height: "2rem",
}));

const StyledTab = styled(Tab)(({ theme, label }) => ({
	textTransform: "none",
	fontSize: "0.875rem",
	fontWeight: 500,
	gap: "0.5rem",
	letterSpacing: "0.025rem",
	background: "transparent",
	"&.Mui-selected": {
		background: theme.palette.mode === "dark" ? "#16855b" : "#E8F6F1",
		color: theme.palette.mode === "dark" ? "#7fffcf" : "#1BA672",
		borderRadius: "12px",
		border: theme.palette.mode === "dark" ? "1px solid #7fffcf" : "1px solid #1BA672",
	},
})) as typeof Tab;

const HorizontalLine = styled("div")(({ theme }) => ({
	borderTop: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "1px solid #D9D9D9",
	width: "100%",
	color: "rgba(0, 0, 0, 0.12)",
}));

const FilterPaper = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	padding: "1rem",
	backgroundColor: theme.palette.background.paper,
	borderRadius: "0px 0px 16px 16px",
	backgroundImage: "none",
}));

const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const CommonContainerSubCategory = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start",
	width: "100%",
	gao: "0.25rem",
	padding: "1rem 0rem 0.5rem 0rem",
}));

const HomeContainer = styled("div")(({ theme }) => ({
	"@keyframes fade": {
		"0%": {
			opacity: 0,
		},
		"100%": {
			opacity: 1,
		},
	},
}));

const ErrorContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	alignContent: "flex-start",
	padding: "var(--Spacing-spacing-md, 16px) var(--Spacing-spacing-l, 24px);",
	width: "25rem",
	height: "100%",
	backgroundColor: "#FDF0E5",
	gap: "1rem",
	borderRadius: "1rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
}));

const CollapseContainer = styled(Collapse)(({ theme }) => ({
	width: "100%",
	backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
	borderRadius: "16px",
	zIndex: 20,
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const FirstDiv = styled("div")(({ theme }) => ({}));
const SecondDiv = styled("div")(({ theme }) => ({}));
const Search = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const router = useRouter();

	// const router = useRouter();
	const theme = useTheme();

	/*

	* Tabs state and functions buy, rent, open for business services and business users

	*/

	const [value, setValue] = React.useState(
		search_filter_state.transaction_type.is_applied
			? search_filter_state.transaction_type.selected_options[0]?.title
			: "Buy",
	);

	/*

	* Function to handle the business users agent, developers, landowners, professionals, users.

	*/

	const handleChangeBusinessUsers = (value: string) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "property_category",
				newOptions: [],
			}),
		),
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [],
				}),
			),
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "service_type",
					newOptions: [],
				}),
			),
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "service_subtype",
					newOptions: [],
				}),
			),
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));
		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: "business_profile_type",
				isAccordionOpen: true,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "business_profiles",
						title: "Business Profiles",
						value: "business_profiles",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);

		const business_profile_type = search_filter_state.business_profile_type.active_options.map((item) => ({
			...item,
			is_selected: item.title === value ? true : false,
		}));

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: business_profile_type,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		// if (value !== newValue) {
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return (
				search_filter_state[key as SearchFilterId].is_applied === true &&
				key !== "transaction_type" &&
				key !== "search_type" &&
				key !== "location"
			);
		});
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
		});
		// }

		setValue(newValue);
		/*

		* Setting the search filter selected options based on the transaction type

		*/

		if (newValue === "Buy" || newValue === "Open for business" || newValue === "Rent") {
			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: "property_category",
					newOptions: property_category_options_active,
				}),
			);
			/*

			* updating service is applied and is visible

			*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "service_type",
					newOptions: [],
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "service_subtype",
						newOptions: [],
					}),
				),
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			/*

			* updating properties is applied and is visible

			*/
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));

			/*

			 * need to update transaction type as well as search type same with isApplied true.

				! default value is done in index.tsx page for transaction_type

			*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [
						{
							id: newValue === "Open for business" ? "ofb" : newValue.toLowerCase(),
							title: newValue,
							value: newValue === "Open for business" ? "ofb" : newValue.toLowerCase(),
						},
					],
				}),
			);

			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: true,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: "properties",
							title: "Properties",
							value: "properties",
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);

			if (newValue === "Rent") {
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "rent",
								title: "Rent",
								value: "rent",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "security_deposit", isVisible: true }));
			}
			if (newValue === "Open for business") {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "ofb",
								title: "Open for business",
								value: "ofb",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: ofb_property_category_options_active,
					}),
				);
			}
		} else if (newValue === "Services") {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_category",
					newOptions: [],
				}),
			),
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_type",
						newOptions: [],
					}),
				),
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue.toLocaleLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
		} else if (newValue === "Business Users") {
			if (search_filter_state.business_profile_type.selected_options.length > 0) {
				handleChangeBusinessUsers(search_filter_state?.business_profile_type?.selected_options[0]?.title);
			}
		}
	};
	/*

	* Filter transition effect state

	*/
	const [checked, setChecked] = React.useState(false);

	/*

	* Function to handle the filter state


	*/
	const handleChangeFilters = () => {
		setChecked((prev) => !prev);
	};

	/*

	* SubCategory i.e (Budget, Bedroom, Status, Listed by, Facing)

	*/
	const [SubCategoryChecked, setSubCategoryChecked] = React.useState<string>("");

	const handleClickSubCategoryChips = (data: string) => {
		setSubCategoryChecked(data);
	};

	const handleDeleteSubCategoryChips = (data: string) => {
		if (SubCategoryChecked === data) {
			setSubCategoryChecked("");
		}
	};

	React.useEffect(() => {
		if (
			search_filter_state.property_category.selected_options[0]?.id ||
			search_filter_state.property_type.selected_options[0]?.id
		) {
			setSubCategoryChecked("");
		}
	}, [search_filter_state.property_category, search_filter_state.property_type]);
	/*

	* Function for Search Filters for property category

	*/

	/*

	* Check the subcategory and render the content accordingly

	*/
	const SubCategoryChipsComponent = (
		<Stack
			direction="row"
			spacing={2}
		>
			{searchanimationsettings?.PropertySubCategory?.map((data: any, id: any) => (
				<PropertyChips
					key={id}
					sx={{
						fontSize: "1rem",
						backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						color:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "#ffffff"
									: "rgba(255, 255, 255, 1)"
								: SubCategoryChecked === data
									? "rgba(98, 56, 22, 1)"
									: "black",
						border:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.12)",

						"&.MuiChip-clickable:hover": {
							backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						},
					}}
					label={data}
					clickable
					color="default"
					variant="outlined"
					deleteIcon={SubCategoryChecked === data ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					onDelete={() => {
						SubCategoryChecked === data ? handleDeleteSubCategoryChips(data) : handleClickSubCategoryChips(data);
					}}
					onClick={() => {
						SubCategoryChecked === data ? handleDeleteSubCategoryChips(data) : handleClickSubCategoryChips(data);
					}}
				/>
			))}
		</Stack>
	);
	/*

	* Changed from switch to css, even if conditions doesnt work. on switch or if conditions state changes to empty. so the whole css dispaly none

	*/
	const dataToRenderSubCategoryContent = (Subvalue: string) => {
		return (
			<React.Fragment>
				<CommonContainerSubCategory
					sx={{
						gap: "0.5rem",
						display: Subvalue === "Budget" ? "flex" : "none",
					}}
				>
					<BudgetComponent
						search_id="budget"
						dispatch={dispatch}
						search_filter_state={search_filter_state.budget}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Bedroom" ? "flex" : "none",
					}}
				>
					<BedroomComponent
						search_id="bedrooms"
						dispatch={dispatch}
						search_filter_state={search_filter_state.bedrooms}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Status" ? "flex" : "none",
					}}
				>
					<PropertyStatusComponent
						search_id="property_status"
						dispatch={dispatch}
						search_filter_state={search_filter_state.property_status}
						propertyType={search_filter_state.property_type.selected_options[0]?.id as string}
						transactionType={search_filter_state.transaction_type.selected_options[0]?.id as string}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Listed by" ? "flex" : "none",
					}}
				>
					<ListedByComponent
						search_id="listed_by"
						dispatch={dispatch}
						search_filter_state={search_filter_state.listed_by}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: Subvalue === "Facing" ? "flex" : "none",
					}}
				>
					<FacingDirectionComponent
						search_id="land_facing"
						dispatch={dispatch}
						search_filter_state={search_filter_state.land_facing}
					/>
				</CommonContainerSubCategory>
			</React.Fragment>
		);
	};

	const SubCategoryContentComponent = <>{dataToRenderSubCategoryContent(SubCategoryChecked)}</>;

	/*

	* if tabs state changes then render the content accordingly

	*/

	const TransitionDivComponentToRender = (value: string) => {
		const property = value === "Buy" || value === "Rent" ? value : null;
		switch (value) {
			case property:
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
							defaultExpanded
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: theme.palette.mode === "dark" ? "transparent" : "rgba(252, 128, 25, 0.16)",
											color: theme.palette.mode === "dark" ? "#FF8A29" : "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									fontWeight: 500,
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{router.locale === "ar-AE"
									? "اختر نوع العقار"
									: router.locale === "kn-IN"
										? "ಆಸ್ತಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
										: "Select property type"}
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "8px 14px 0px",
									"&.MuiAccordionDetails-root": {
										padding: "0rem 0.875rem 0.5rem 0.875rem",
									},
								}}
							>
								<PropertyCategory
									search_id={"property_category"}
									search_filter_state={search_filter_state.property_category}
									dispatch={dispatch}
									searchanimationsettings={searchanimationsettings}
									transaction_type={search_filter_state.transaction_type.selected_options[0]?.id as string}
								/>

								{search_filter_state.property_category.is_applied &&
								search_filter_state.property_category.selected_options[0]?.id !== "warehousing" ? (
									<PropertyType
										search_id="property_type"
										property_category_value={
											search_filter_state.property_category?.selected_options[0]?.value as string
										}
										dispatch={dispatch}
										search_filter_state={search_filter_state.property_type}
										searchanimationsettings={searchanimationsettings}
									/>
								) : null}
							</AccordionDetails>
						</Accordion>

						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: theme.palette.mode === "dark" ? "transparent" : "rgba(252, 128, 25, 0.16)",
											color: theme.palette.mode === "dark" ? "#FF8A29" : "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									fontWeight: 500,
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{router.locale === "ar-AE"
									? "مزيد من الفلاتر"
									: router.locale === "kn-IN"
										? "ಹೆಚ್ಚಿನ ಫಿಲ್ಟರ್‌ಗಳು"
										: "More filters"}
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "4px 14px 0px",
								}}
							>
								{SubCategoryChipsComponent}
								{SubCategoryContentComponent}
							</AccordionDetails>
						</Accordion>
					</div>
				);
			case "Open for business":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
							defaultExpanded
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: theme.palette.mode === "dark" ? "transparent" : "rgba(252, 128, 25, 0.16)",
											color: theme.palette.mode === "dark" ? "#FF8A29" : "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									fontWeight: 500,
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{router.locale === "ar-AE"
									? "اختر نوع أو إف بي"
									: router.locale === "kn-IN"
										? "OFB ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
										: "Select OFB type"}
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "8px 14px 0px",
									"&.MuiAccordionDetails-root": {
										padding: "0rem 0.875rem 0rem 0.875rem",
									},
								}}
							>
								<PropertyCategory
									search_id={"property_category"}
									search_filter_state={search_filter_state.property_category}
									dispatch={dispatch}
									searchanimationsettings={searchanimationsettings}
									transaction_type={search_filter_state.transaction_type.selected_options[0]?.id as string}
								/>

								{search_filter_state.property_category?.selected_options[0]?.id === "public_infrastructure" ||
								search_filter_state.property_category?.selected_options[0]?.id === "joint_development" ||
								search_filter_state.property_category?.selected_options[0]?.id === "joint_venture" ||
								search_filter_state.property_category?.selected_options[0]?.id === undefined ? null : (
									<PropertyType
										search_id="property_type"
										property_category_value={
											search_filter_state.property_category?.selected_options[0]?.value as string
										}
										dispatch={dispatch}
										search_filter_state={search_filter_state.property_type}
										searchanimationsettings={searchanimationsettings}
									/>
								)}
							</AccordionDetails>
						</Accordion>
					</div>
				);
			case "Services":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
							defaultExpanded
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: "rgba(252, 128, 25, 0.16)",
											color: "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									fontWeight: 500,
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{router.locale === "ar-AE"
									? "اختر فئة الخدمة"
									: router.locale === "kn-IN"
										? "ಸೇವಾ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
										: "Select service category"}
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "0px 14px 0px",
								}}
							>
								<ServiceCategoryComponent
									search_id={"service_type"}
									search_filter_state={search_filter_state.service_type}
									dispatch={dispatch}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: "rgba(252, 128, 25, 0.16)",
											color: "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									fontWeight: 500,
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{router.locale === "ar-AE"
									? "اختر نوع الخدمة"
									: router.locale === "kn-IN"
										? "ಸೇವಾ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
										: "Select service type"}
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "8px 14px 0px",
								}}
							>
								<ServiceSubTypeComponent
									search_id={"service_subtype"}
									search_filter_state={search_filter_state.service_subtype}
									dispatch={dispatch}
								/>
							</AccordionDetails>
						</Accordion>
					</div>
				);
			case "Business Users":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Accordion
							sx={{
								boxShadow: "none",
								backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
								backgroundImage: "none",
								"&.Mui-expanded": {
									margin: "0rem",
								},
							}}
							defaultExpanded
						>
							<AccordionSummary
								expandIcon={
									<ExpandMoreIcon
										sx={{
											width: "1.25em",
											height: "1.25em",
											borderRadius: "8px",
											background: "rgba(252, 128, 25, 0.16)",
											color: "rgba(98, 56, 22, 1)",
										}}
									/>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								sx={{
									fontWeight: 500,
									"&.MuiAccordionSummary-root.Mui-expanded": {
										minHeight: "0rem",
									},
									"& .MuiAccordionSummary-content.Mui-expanded": {
										margin: "0.5rem 0rem 0.5rem 0rem",
									},
								}}
							>
								{router.locale === "ar-AE"
									? "اختر نوع مستخدم الأعمال"
									: router.locale === "kn-IN"
										? "ವ್ಯಾಪಾರ ಬಳಕೆದಾರ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
										: "Select business user type"}
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: "0px 14px 0px",
								}}
							>
								<FormControl>
									<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										sx={{ gap: "0.5rem", fontSize: "1rem", fontWeight: 400 }}
									>
										{search_filter_state.business_profile_type.active_options.map((data, id) => (
											<FormControlLabel
												key={id}
												value={data.value}
												control={<Radio />}
												label={
													data.title === "Landowners"
														? "Owners"
														: data.title === "Professionals"
															? "Service Professionals"
															: data.title
												}
												onChange={() => handleChangeBusinessUsers(data.title)}
											/>
										))}
									</RadioGroup>
								</FormControl>
							</AccordionDetails>
						</Accordion>
					</div>
				);
		}
	};

	const CheckerRef = React.useRef<HTMLDivElement>(null);
	/*

	* if user clicks outside the filter then close the filter and remove background opacity. HomeAccordion dispatch is handling that ez pz lemon squeezy

	*/
	const isDesktop = useMediaQuery(theme.breakpoints.up("md1220"));
	React.useEffect(() => {
		if (isDesktop) {
			const UserClickedOutsideMf = (event: MouseEvent) => {
				if (CheckerRef.current && !CheckerRef.current.contains(event.target as Node)) {
					setChecked(false);
					dispatch(
						setSearchAnimationDetailsThunk({
							StartAnimate: searchanimationsettings.StartAnimate,
							TriggerSearch: searchanimationsettings.TriggerSearch,
							ResetSearch: searchanimationsettings.ResetSearch,
							PropertySubCategory: searchanimationsettings.PropertySubCategory,
							HomeAccordion: false,
							OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
						}),
					);
				}
			};
			document.addEventListener("mousedown", UserClickedOutsideMf);
			return () => {
				document.removeEventListener("mousedown", UserClickedOutsideMf);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop]);

	const ClearAllFilters = () => {
		/*

		* let's get all the applied filters and store it as string array

		*/
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return (
				search_filter_state[key as SearchFilterId].is_applied === true &&
				key !== "transaction_type" &&
				key !== "search_type"
			);
		});

		/*

		* let's map the filters applied and update the search filter applied to false and selected options to empty array

		*/
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
		});
	};

	const [openErrorLocation, setOpenErrorLocation] = React.useState(false);

	React.useEffect(() => {
		if (openErrorLocation) {
			search_filter_state.location.is_applied ? setOpenErrorLocation(false) : null;
		}
	}, [search_filter_state.location, openErrorLocation]);

	React.useEffect(() => {
		if (checked) {
			document.body.style.overflow = "hidden";
		} else if (!checked) {
			document.body.style.overflow = "auto";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [checked]);

	return (
		<React.Fragment>
			<div
				ref={CheckerRef}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					zIndex: 20,
				}}
			>
				<Container
					sx={{
						borderRadius: checked ? "16px 16px 0px 0px" : "16px",
					}}
				>
					<HomeContainer
						sx={{
							height: "70%",
							margin: "0.75rem 0rem 0rem 0rem",
							width: "100%",
						}}
					>
						<StyledTabs
							sx={{
								"&.MuiTab-root": {
									bacground: "transparent",
									minHeight: "0rem",
								},
							}}
							value={value}
							onChange={handleChange}
							aria-label="wrapped label tabs example"
						>
							<StyledTab
								value="Buy"
								label={router.locale === "ar-AE" ? "شراء" : router.locale === "kn-IN" ? "ಖರೀದಿಸಿ" : "Buy"}
							/>
							<StyledTab
								value="Rent"
								label={router.locale === "ar-AE" ? "إيجار" : router.locale === "kn-IN" ? "ಬಾಡಿಗೆ" : "Rent"}
							/>
							<StyledTab
								value="Services"
								label={router.locale === "ar-AE" ? "الخدمات" : router.locale === "kn-IN" ? "ಸೇವೆಗಳು" : "Services"}
							/>
							<StyledTab
								value="Open for business"
								label={router.locale === "ar-AE" ? "أو إف بي" : router.locale === "kn-IN" ? "OFB ಗಳು" : "OFBs"}
							/>
							<StyledTab
								value="Business Users"
								label={
									router.locale === "ar-AE"
										? "مستخدمي الأعمال"
										: router.locale === "kn-IN"
											? "ವ್ಯಾಪಾರ ಬಳಕೆದಾರರು"
											: "Business users"
								}
							/>
						</StyledTabs>
					</HomeContainer>
					<HorizontalLine
						sx={{
							display: "flex",
							margin: "0.5rem 0rem 0rem 0rem",
						}}
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							width: "100%",
							height: "100%",
						}}
					>
						<FirstDiv
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: "0.5rem",
								padding: "0.5rem",
								cursor: "pointer",
								width: "17%",
								[theme.breakpoints.down("md1220")]: {
									width: "22%",
								},
							}}
							onClick={() => {
								if (window.scrollY > 0) {
									window.scrollTo({ top: 0, behavior: "smooth" });
									setTimeout(() => {
										handleChangeFilters();
										dispatch(
											setSearchAnimationDetailsThunk({
												StartAnimate: searchanimationsettings.StartAnimate,
												TriggerSearch: searchanimationsettings.TriggerSearch,
												ResetSearch: searchanimationsettings.ResetSearch,
												PropertySubCategory: searchanimationsettings.PropertySubCategory,
												HomeAccordion: !checked,
												OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
											}),
										);
									}, 500);
								} else {
									handleChangeFilters();
									dispatch(
										setSearchAnimationDetailsThunk({
											StartAnimate: searchanimationsettings.StartAnimate,
											TriggerSearch: searchanimationsettings.TriggerSearch,
											ResetSearch: searchanimationsettings.ResetSearch,
											PropertySubCategory: searchanimationsettings.PropertySubCategory,
											HomeAccordion: !checked,
											OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
										}),
									);
								}
							}}
						>
							{(() => {
								if (value === "Buy" || value === "Rent") {
									return router.locale === "ar-AE"
										? "أنواع العقارات"
										: router.locale === "kn-IN"
											? "ಆಸ್ತಿ ಪ್ರಕಾರಗಳು"
											: "Property types";
								} else if (value === "Open for business") {
									return router.locale === "ar-AE"
										? "أنواع أو إف بي"
										: router.locale === "kn-IN"
											? "OFB ಪ್ರಕಾರಗಳು"
											: "OFB types";
								} else if (value === "Services") {
									return router.locale === "ar-AE"
										? "أنواع الخدمات"
										: router.locale === "kn-IN"
											? "ಸೇವೆಗಳ ಪ್ರಕಾರಗಳು"
											: "Service types";
								} else {
									return router.locale === "ar-AE"
										? "أنواع مستخدمي الأعمال"
										: router.locale === "kn-IN"
											? "ವ್ಯಾಪಾರ ಬಳಕೆದಾರರ ಪ್ರಕಾರಗಳು"
											: "Business types";
								}
							})()}
						</FirstDiv>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							{checked ? (
								<KeyboardArrowUpIcon
									sx={{
										cursor: "pointer",
										color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
									}}
									onClick={() => {
										handleChangeFilters();

										dispatch(
											setSearchAnimationDetailsThunk({
												StartAnimate: searchanimationsettings.StartAnimate,
												TriggerSearch: searchanimationsettings.TriggerSearch,
												ResetSearch: searchanimationsettings.ResetSearch,
												PropertySubCategory: searchanimationsettings.PropertySubCategory,
												HomeAccordion: !checked,
												OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
											}),
										);
									}}
								/>
							) : (
								<KeyboardArrowDownIcon
									sx={{
										cursor: "pointer",
										color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
									}}
									onClick={() => {
										handleChangeFilters();
										dispatch(
											setSearchAnimationDetailsThunk({
												StartAnimate: false,
												TriggerSearch: false,
												ResetSearch: false,
												PropertySubCategory: searchanimationsettings.PropertySubCategory,
												HomeAccordion: !checked,
												OpenLocationDrawer: false,
											}),
										);
									}}
								/>
							)}
						</div>
						<VerticalLine />
						<SecondDiv
							sx={{
								display: "flex",
								flexDirection: "row",
								marginLeft: "0.5rem",
								width: "83%",
								alignItems: "center",
								[theme.breakpoints.down("md1220")]: {
									width: "78%",
								},
							}}
						>
							<LocationAutoComplete
								search_id="location"
								dispatch={dispatch}
								search_filter_state={search_filter_state.location}
							/>

							<BeegruButton
								variant="contained"
								size="large"
								onClick={() => {
									if (!search_filter_state.location.is_applied) {
										setOpenErrorLocation(true);
									} else {
										router
											.push("/search", "", { shallow: true, locale: router.locale })
											.then(() => {
												dispatch(
													setSearchAnimationDetailsThunk({
														StartAnimate: true,
														TriggerSearch: true,
														ResetSearch: false,
														PropertySubCategory: searchanimationsettings.PropertySubCategory,
														HomeAccordion: false,
														OpenLocationDrawer: false,
													}),
												);
											})
											.catch((error) => {
												console.error("Navigation error:", error);
											});
									}
								}}
							>
								{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? " ಹುಡುಕಿ" : "Search"}
							</BeegruButton>
						</SecondDiv>
					</div>
				</Container>

				<CollapseContainer in={checked}>
					<FilterPaper>
						{TransitionDivComponentToRender(value)}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "2.5rem",
							}}
						>
							<BeegruButton
								variant="text"
								size="medium"
								onClick={() => {
									ClearAllFilters();
								}}
							>
								{router.locale === "ar-AE"
									? "إعادة تعيين الفلاتر"
									: router.locale === "kn-IN"
										? " ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಮರುಹೊಂದಿಸಿ"
										: "Reset filters"}
							</BeegruButton>
							<BeegruButton
								variant="outlined"
								size="medium"
								onClick={() => {
									if (!search_filter_state.location.is_applied) {
										null;
									} else {
										router
											.push("/search", "", { shallow: true, locale: router.locale })
											.then(() => {
												dispatch(
													setSearchAnimationDetailsThunk({
														StartAnimate: true,
														TriggerSearch: true,
														ResetSearch: false,
														PropertySubCategory: searchanimationsettings.PropertySubCategory,
														HomeAccordion: false,
														OpenLocationDrawer: false,
													}),
												);
											})
											.catch((error) => {
												console.error("Navigation error:", error);
											});
									}
								}}
							>
								{router.locale === "ar-AE"
									? "قم بتطبيق فلاتر البحث"
									: router.locale === "kn-IN"
										? "ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಅನ್ವಯಿಸಿ"
										: "Apply filters"}
							</BeegruButton>
						</div>
					</FilterPaper>
				</CollapseContainer>
				{!checked ? (
					<Zoom in={openErrorLocation}>
						<ErrorContainer
							sx={{
								marginTop: "1rem",
								zIndex: 55,
								color: "#5F2B01",
							}}
						>
							<WarningAmberOutlinedIcon
								sx={{
									color: "#C77700",
								}}
							/>
							{router.locale === "ar-AE"
								? "يرجى إدخال الموقع"
								: router.locale === "kn-IN"
									? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
									: "Please enter a location"}
						</ErrorContainer>
					</Zoom>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default Search;
