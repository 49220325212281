import { styled } from "@mui/system";

import { Skeleton } from "@mui/material";
import PropertyLoadingCardMini from "./PropertyLoadingCardMini";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	height: "100%",
}));

const FilterContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "1rem",
	margin: "1rem 0rem 1rem 0rem",
}));

const FilterDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "1rem",
	margin: "1rem 0rem 0rem 0rem",
}));

const PropertyCardDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "1rem 0rem 0rem 0rem",
}));

const PropertyDivContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
}));

const SearchDesktopLayoutSkeleton = () => {
	return (
		<MainContainer>
			<Skeleton
				animation="wave"
				variant="rectangular"
				width="100%"
				height="3.5rem"
				style={{ borderRadius: "8px" }}
			/>
			<FilterContainer>
				<FilterDiv>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="9rem"
						style={{ borderRadius: "8px", margin: "0rem" }}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="9rem"
						style={{ borderRadius: "8px" }}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="9rem"
						style={{ borderRadius: "8px" }}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="9rem"
						style={{ borderRadius: "8px" }}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="9rem"
						style={{ borderRadius: "8px" }}
					/>
					<Skeleton
						animation="wave"
						variant="rectangular"
						width="100%"
						height="9rem"
						style={{ borderRadius: "8px" }}
					/>
				</FilterDiv>
				<PropertyDivContainer>
					{[...Array(3)].map((_, index) => (
						<PropertyCardDiv key={`property-card-${index}`}>
							{[...Array(4)].map((_, idx) => (
								<PropertyLoadingCardMini
									key={`loading-card-${index}-${idx}`}
									source={""}
									isEditor={false}
								/>
							))}
						</PropertyCardDiv>
					))}
				</PropertyDivContainer>
			</FilterContainer>
		</MainContainer>
	);
};

export default SearchDesktopLayoutSkeleton;
