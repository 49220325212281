/* Common Imports */

import { styled } from "@mui/system";

/* Component Imports */

import { Skeleton } from "@mui/material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	rowGap: "0.75rem",
	flexWrap: "wrap",
	/*642*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		gap: "0.75rem",
	},
	/*1107*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		gap: "1rem",
		rowGap: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		width: "75%",
	},
}));

const PropertyCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source" && prop !== "isEditor",
})<{ source: string; isEditor: boolean }>(({ theme, source, isEditor }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "29.65rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: "6.65rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* XS breakpoint */
	[theme.breakpoints.up("xs")]: {
		width: "7.75rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: "8.65rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: "6.65rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: "6.95rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: "7.25rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: "7.75rem",
		height: source === "business_profile_listings" && isEditor ? "12.25rem" : "10rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: "8.1rem",
		height: source === "business_profile_listings" && isEditor ? "12.25rem" : "10rem",
	},
	/*457*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		width: "8.65rem",
		height: source === "business_profile_listings" && isEditor ? "12.5rem" : "10.25rem",
	},
	/*485*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		width: "9.25rem",
		height: source === "business_profile_listings" && isEditor ? "12.75rem" : "10.5rem",
	},
	/*512*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		width: "9.8rem",
		height: source === "business_profile_listings" && isEditor ? "13.25rem" : "11rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: "7.3rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	/*570*/
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: "7.77rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "7.5rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	/*642*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: "7.95rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		width: "8.6rem",
		height: source === "business_profile_listings" && isEditor ? "13.75rem" : "11.5rem",
	},
	/*726*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 126)]: {
		width: "9.25rem",
		height: source === "business_profile_listings" && isEditor ? "13.75rem" : "11.5rem",
	},
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "9.9rem",
		height: source === "business_profile_listings" && isEditor ? "14.25rem" : "12rem",
	},
	/*ipad Air - 820*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "10.65rem",
		height: source === "business_profile_listings" && isEditor ? "15rem" : "12.75rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		width: "11.35rem",
		height: source === "business_profile_listings" && isEditor ? "16rem" : "13.75rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		width: "12.15rem",
		height: source === "business_profile_listings" && isEditor ? "17rem" : "14.75rem",
	},
	/*960*/
	[theme.breakpoints.up("md")]: {
		width: "12.4rem",
		height: source === "business_profile_listings" && isEditor ? "17rem" : "14.75rem",
	},
	/*iPadPro - 1024*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "13.3rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/*1107*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		width: "14.5rem",
		height: source === "business_profile_listings" && isEditor ? "18rem" : "15.75rem",
	},
	/*1190 ~~Desktop Layout Start~~*/
	[theme.breakpoints.up("md1190")]: {
		width: "15.65rem",
		height: source === "business_profile_listings" && isEditor ? "18.75rem" : "16.5rem",
	},
	/*720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "12.45rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "13.2rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "12.8rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "11.75rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "12rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	padding: "0rem 0.9rem 0rem 0.9rem",
	width: "100%",
	height: "100%",
	zIndex: 0,
}));

const TitlePriceSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
	margin: "0.25rem 0rem 0.5rem 0rem",
}));

const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	margin: "0rem 0rem 0.5rem 0rem",
	gap: "0.25rem",
}));

const PropertyLoadingCardMini = ({ source, isEditor }: { source?: string; isEditor?: boolean | undefined }) => {
	return (
		<MainContainer>
			<PropertyCardContainer
				source={source ? source : ""}
				isEditor={isEditor !== undefined ? isEditor : false}
			>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="100%"
					height="100%"
					style={{ borderRadius: "10px 10px 0px 0px" }}
				/>

				<PropertyCardInfoContainer>
					<TitlePriceSkeleton>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="30%"
							height="0.75rem"
							style={{ borderRadius: "0.25rem" }}
						/>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.75rem"
							style={{ borderRadius: "0.25rem" }}
						/>
					</TitlePriceSkeleton>

					<InfoContainer>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.65rem"
							style={{ borderRadius: "0.25rem" }}
						/>

						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.65rem"
							style={{ borderRadius: "0.25rem" }}
						/>

						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.65rem"
							style={{ borderRadius: "0.25rem" }}
						/>

						{source === "business_profile_listings" && isEditor ? (
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="80%"
								height="0.75rem"
								style={{ borderRadius: "0.25rem" }}
							/>
						) : null}
					</InfoContainer>
				</PropertyCardInfoContainer>
			</PropertyCardContainer>
		</MainContainer>
	);
};

export default PropertyLoadingCardMini;
