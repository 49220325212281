/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Skeleton } from "@mui/material";

const CoverBannerSkeleton = styled(Skeleton)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	height: "9rem",
	borderRadius: "1rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0.5rem 0rem 0.75rem 0rem",
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	flexWrap: "wrap",
	width: "100%",
	gap: "0.5rem",
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const BannerContainer = styled(Skeleton)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	borderRadius: "1rem",
	margin: "0rem 0rem 1rem 0rem",
}));

const ServiceContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
}));

const HomePageMobileLoading = () => {
	return (
		<React.Fragment>
			<div
				style={{
					padding: "4rem 0rem 0rem 0rem",
				}}
			>
				<CoverBannerSkeleton
					animation="wave"
					variant="rectangular"
					width="100%"
				/>

				<Container>
					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="2rem"
					/>

					<CardsContainer>
						<CategoryCard>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="3rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="3rem"
								style={{ borderRadius: "0.5rem" }}
							/>
						</CategoryCard>
						<CategoryCard>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="3rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="3rem"
								style={{ borderRadius: "0.5rem" }}
							/>
						</CategoryCard>
					</CardsContainer>
				</Container>

				<BannerContainer
					animation="wave"
					variant="rectangular"
					width="100%"
					height="5rem"
				/>

				<ServiceContainer>
					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="2rem"
					/>
					<CardsContainer>
						<CategoryCard>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
						</CategoryCard>
					</CardsContainer>
				</ServiceContainer>
			</div>
		</React.Fragment>
	);
};

export default HomePageMobileLoading;
