import { styled } from "@mui/system";

import { Skeleton } from "@mui/material";
import FeaturedLoadingCard from "./FeaturedLoadingCard";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
}));

const FilterDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0.5rem 0rem 0.5rem 0rem",
}));

const PropertyCardDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0.5rem 0rem 0.5rem 0rem",
}));

const SearchMobileLayoutSkeleton = () => {
	return (
		<MainContainer>
			{/* <Skeleton
				animation="wave"
				variant="rectangular"
				width="100%"
				height="2.5rem"
				style={{ borderRadius: "8px" }}
			/>

			<Skeleton
				animation="wave"
				variant="text"
				width="50%"
				height="1.5rem"
				style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}
			/>
			<PropertyCardDiv>
				<FeaturedLoadingCard />
			</PropertyCardDiv>

			<Skeleton
				animation="wave"
				variant="text"
				width="50%"
				height="1.5rem"
				style={{ margin: "1rem 0rem 0.5rem 0rem" }}
			/> */}

			<FilterDiv>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="30%"
					height="2rem"
					style={{ borderRadius: "8px" }}
				/>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="20%"
					height="2rem"
					style={{ borderRadius: "8px" }}
				/>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="30%"
					height="2rem"
					style={{ borderRadius: "8px" }}
				/>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="20%"
					height="2rem"
					style={{ borderRadius: "8px" }}
				/>
			</FilterDiv>

			<Skeleton
				animation="wave"
				variant="text"
				width="70%"
				height="2rem"
			/>

			<PropertyCardDiv>
				<FeaturedLoadingCard />
			</PropertyCardDiv>
		</MainContainer>
	);
};

export default SearchMobileLayoutSkeleton;
